import {
  ComponentDefinition,
  DeviceType,
  EditorReadyFn,
  EditorReadyOptions,
  EditorSDK,
  ExportsFn,
  GetAppManifest,
} from '@wix/platform-editor-sdk';
import { AppEditorApi } from '@wix/platform-editor-sdk/js/definitions/appEditorApi';
import { CompStructure } from '@wix/document-services-types';

import * as componentsWrapper from './wrappers/components';
import * as pagesService from './services/pages';
import { hasSocialPages } from './services/pages';
import * as constants from './constants';
import * as applicationState from './applicationState';
import enforceSequentiality, { startSequentialPromises, stopSequentialPromises } from './enforceSequentiality';
import { BADGES_BM_URL, MEMBERS_ACCOUNT_BM_URL, SITE_MEMBERS_BM_URL } from './constants/routes';
import {
  initializeMonitoring,
  interactionEnded,
  interactionFailed,
  interactionStarted,
  log,
  toMonitored,
} from '../utils/monitoring';
import { createAppManifest } from './manifest';
import { parseStaticsUrlFromEditorScriptUrl } from './services/urls';
import * as appState from './services/applicationState';
import { createBIService } from '../utils/bi';
import * as membersIntegrationApi from './services/integration';
import { registerAlwaysAvailableApps } from './services/integration';
import { publishSettingsForMembersAreaApps } from './services/members-area';
import {
  maybeConductExperiments,
  shouldDisableEditorReadyTransaction,
  shouldRevertOnInstallationErrors,
} from '../utils/experiments';
import { runAndWaitForApproval } from './wrappers/transactions';
import {
  openRemoveLoginBarConfirmationPanel,
  openRemovePagePanel,
  openUninstallPanel,
} from './wrappers/platformPanels';
import { onManagePages, openGeneralSettings, openMemberPrivacySettingsBM } from './wrappers/panels';
import { getTranslationFunction } from '../i18n';
import { createPublicApi } from './public-api';
import { createPrivateApi } from './private-api';
import * as membersLogic from './membersLogic';
import {
  maybeFixBrokenMenuItems,
  maybeFixLoginBarResponsiveLayout,
  maybeSetManagingAppDefIdForMAPages,
  removeBrokenInstallation,
  verifyMyAccountPage,
  verifyNoMissingLoginInADI,
} from './data-fixers';
import { EventType, MembersAreaEventType, MembersAreaOnEventFn } from '../types/EditorAppModule';
import { withTimeoutMonitor } from '../utils/promise-timeout';

const { APP_TOKEN } = constants;
const INSTALLATION_TIMEOUT_AMOUNT = 15000;

const onEvent: MembersAreaOnEventFn = async ({ eventType, eventPayload }, editorSDK) => {
  enforceSequentiality('onEventHandler', async () => {
    const isReady = await applicationState.isApplicationReady(editorSDK);
    if (!isReady) {
      return;
    }
    try {
      const _routers = await editorSDK.routers.getAll(APP_TOKEN);
      switch (eventType) {
        case MembersAreaEventType.generalSettings: {
          openGeneralSettings({ eventPayload, editorSDK });
          break;
        }
        case MembersAreaEventType.memberPrivacyDashboard: {
          openMemberPrivacySettingsBM(editorSDK);
          break;
        }
        case MembersAreaEventType.createBlankPage: {
          onManagePages({ eventPayload, editorSDK });
          break;
        }
        case MembersAreaEventType.managePages: {
          onManagePages({ eventPayload: undefined, editorSDK });
          break;
        }
        case EventType.pageDeleted: {
          // Handling when a verticals TPA section is being deleted - removes menu items and router patterns, the page is removed by platform
          // Separate applications deletion (added with "addApplication") is handled by handleVerticalDeletion
          // To do: ask Editor Platform to do this on their side when deleting
          // Seems like we can't return the promise because it then does not execute on e.g. Bookings deletion..
          enforceSequentiality('handleVerticalSectionDeletion', () =>
            toMonitored('handleVerticalSectionDeletion', () =>
              membersIntegrationApi.handleVerticalSectionDeletion(editorSDK, eventPayload.pageRole),
            ),
          );
          break;
        }
        case MembersAreaEventType.uninstall: {
          openUninstallPanel(editorSDK);
          break;
        }
        case MembersAreaEventType.removePage: {
          openRemovePagePanel(editorSDK, eventPayload.pageRef);
          break;
        }
        case MembersAreaEventType.renameRouter:
          hasSocialPages(editorSDK).then((containsSocialPages) => {
            const height = containsSocialPages
              ? constants.RENAME_ROUTER_PANEL_HEIGHT + 150
              : constants.RENAME_ROUTER_PANEL_HEIGHT;
            editorSDK.editor.openModalPanel(APP_TOKEN, {
              url: './assets/renameRouter.html',
              width: 744,
              height,
              shouldHideHeader: true,
              initialData: { routers: _routers, ...eventPayload },
            });
          });
          break;
        case EventType.componentAddedToStage:
          await componentsWrapper.handleCompAddedToStage(editorSDK, eventPayload.compRef);
          break;
        case EventType.siteWasPublished:
          publishSettingsForMembersAreaApps(editorSDK);
          break;

        // Apps manager
        case EventType.appActionClicked:
          switch (eventPayload && eventPayload.actionId) {
            case 'openMembersAreaPagesPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'pagesPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'addMemberPage': {
              onManagePages({ eventPayload, editorSDK });
              break;
            }
            case 'openMembersAddPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'addPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'openMembersAccountBmDashboard':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: MEMBERS_ACCOUNT_BM_URL, closeOtherPanels: true });
              break;
            case 'openBadgesPage':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: BADGES_BM_URL, closeOtherPanels: true });
              break;
            case 'openSiteMembersDashboard':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: SITE_MEMBERS_BM_URL, closeOtherPanels: true });
              break;
            default:
              break;
          }
          break;

        /* end of possibly unused events */
        default:
          console.log(eventType, eventPayload);
      }
    } catch (e) {
      throw e;
    }
  });
};

async function maybeInstallMembersArea(editorSDK: EditorSDK, options: EditorReadyOptions) {
  if (!(await membersLogic.shouldInstall(editorSDK, options.firstInstall))) {
    return;
  }

  try {
    const monitoredPromise = () => toMonitored('install', () => membersLogic.install(editorSDK, options));
    await withTimeoutMonitor('installation', INSTALLATION_TIMEOUT_AMOUNT, monitoredPromise);
  } catch (e) {
    if (!(await shouldRevertOnInstallationErrors())) {
      log('Removing initial installation as it failed', { extra: { error: (e as Error).toString() } });
      await removeBrokenInstallation(editorSDK, true, true);
    }
    throw e;
  }
}

const editorReady: EditorReadyFn = async (editorSDK, _appToken, options) => {
  maybeConductExperiments();

  appState.setEditorSDK(editorSDK);
  appState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl));
  // @ts-expect-error - blogWriterProfilesOnly does not exist on Origin.info type in Editor platform sdk types
  appState.setIsBlogWriterProfilesOnly(!!options.origin.info?.blogWriterProfilesOnly);
  appState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE');
  appState.setIsADI(options.origin.type.indexOf('ADI') === 0);

  try {
    await initializeMonitoring(editorSDK, options);
  } catch (e) {}

  const biService = await createBIService({ editorSDK, options });

  try {
    interactionStarted('editorReady');

    const editorReadyTransaction = async () => {
      await maybeSetManagingAppDefIdForMAPages({ editorSDK, options });

      await pagesService.setStateForPages(editorSDK);

      // MA-84 investigation, making sure My Account page is always there as it has to be
      await verifyMyAccountPage(options, editorSDK);

      // Install MA and delete it if anything goes wrong
      await maybeInstallMembersArea(editorSDK, options);

      // Try to solve some issues like duplicated menu items and etc, where MA is corrupted but doesn't have to be deleted
      await maybeFixBrokenMenuItems(editorSDK);

      // Try to fix login bar responsive layout on editorX
      await maybeFixLoginBarResponsiveLayout(editorSDK);

      // Remove MA if it is still unsuccessfully installed
      const successfullyInstalled = await applicationState.isApplicationReady(editorSDK, { shouldLog: true });
      if (successfullyInstalled) {
        // OB-19052 fixer - adds login bar when missing in ADI
        await verifyNoMissingLoginInADI(options.firstInstall, editorSDK);
      } else {
        if (options.firstInstall && (await shouldRevertOnInstallationErrors())) {
          log('isApplicationReady returned false after first install');
          throw new Error('MA not installed successfully');
        }
        stopSequentialPromises();
      }
      await registerAlwaysAvailableApps(editorSDK);
      await componentsWrapper.registerToComponentAddedToStageEvent(editorSDK);
      startSequentialPromises();
    };

    const shouldDisableTransaction = await shouldDisableEditorReadyTransaction();

    if (shouldDisableTransaction) {
      await editorReadyTransaction();
    } else {
      await runAndWaitForApproval(editorSDK, editorReadyTransaction);
    }

    interactionEnded('editorReady');
  } catch (e) {
    const errorMessage = typeof e === 'string' ? e : (e as Error).message;
    biService.logInstallationFailure(errorMessage);
    interactionFailed('editorReady', e as Error);
    console.error('Members Area installation failed');
    console.error(e);
    stopSequentialPromises();
    throw e;
  }
};

const getAppManifest: GetAppManifest = async (options, editorSDK) => {
  const t = await getTranslationFunction(editorSDK, true);
  return createAppManifest(editorSDK, t);
};

async function getControllerPresets() {
  return Promise.resolve([]);
}

function getIsLoginBar(componentType: string) {
  return componentType === 'wysiwyg.viewer.components.LoginSocialBar';
}

function getIsAppWidget(componentType: string) {
  return componentType === 'platform.components.AppWidget';
}

async function getIsEditorInMobileMode(editorSDK: EditorSDK) {
  const editorMode = await editorSDK.editor.info.getEditorMode();
  return editorMode === DeviceType.Mobile;
}

async function beforeComponentRemoved(componentDefinition: ComponentDefinition, editorSDK: EditorSDK) {
  const isEditorInMobileMode = await getIsEditorInMobileMode(editorSDK);

  if (isEditorInMobileMode) {
    return;
  }
  const componentType = componentDefinition.componentType;
  const isAppWidget = getIsAppWidget(componentType);
  const loginBarComponentDefinition = componentDefinition.components?.[0];
  const isComponentObject = typeof loginBarComponentDefinition === 'object';
  const isLoginBar =
    isAppWidget && isComponentObject
      ? getIsLoginBar((loginBarComponentDefinition as CompStructure)?.componentType)
      : getIsLoginBar(componentType);

  if (isLoginBar) {
    await openRemoveLoginBarConfirmationPanel(editorSDK);
  }
}

export const exports_: ExportsFn = (editorSDK) => {
  appState.setEditorSDK(editorSDK);

  return {
    public: createPublicApi(editorSDK),
    private: createPrivateApi(editorSDK),
    editor: {
      beforeComponentRemoved: ({ componentDefinition }) => beforeComponentRemoved(componentDefinition, editorSDK),
    } as unknown as Partial<AppEditorApi>, //  updating @wix/platform-editor-sdk to the latest causes a lot of tests to fail,
  };
};

export { editorReady, onEvent, getAppManifest, getControllerPresets, exports_ as exports };
